import { ContentBlock, useBlockContext } from "eddev/blocks"

const HTMLBlock = (props: ContentBlock) => {
  const ctx = useBlockContext()
  if (typeof props.innerHTML === "string") {
    return <div className="contents wysiwyg" dangerouslySetInnerHTML={{ __html: props.innerHTML }}></div>
  } else {
    return null
  }
}

export default {
  "core/rich-text": HTMLBlock,
  // "core/paragraph": HTMLBlock,
  // "core/embed": (props) => {
  //   return <div>{JSON.stringify(props)}</div>
  // },
}
